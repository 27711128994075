import { Box, Button, Collapse, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useState } from "react";
import Fallback from "src/components/common/Fallback";
import { getProgressReportDetails } from "src/utils/api/dashboard";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { DashBoardTable } from "src/components/common/DashboardTable/entityDashboard";
import ProgressCard from "src/components/common/ProgressCard";
import { ProgressDetailReport } from "src/components/common/DashboardTable/progressDetailReport";
import { useAppSelector } from "src/redux/store";

const ProgressReport = () => {
  const [tableRows, setTableRows] = useState([]);

  const [tableState, setTableState] = useState(false);

  const [queryParams, setQueryParams] = useState<{
    progressReportFormat: string;
    indicatorIds?: string;
    entityIds?: string;
  }>({
    progressReportFormat: "TABLE",
    entityIds: undefined,
  });

  const [boxQueryParams, setBoxQueryParams] = useState<{
    projectStateIds?: string[];
    progressReportFormat?: string;
  }>({
    projectStateIds: undefined,
    progressReportFormat: "BOX",
  });

  const { data: dashboard } = useQuery(
    ["ProgressReportDetails", boxQueryParams],
    () => getProgressReportDetails(boxQueryParams),
    {
      refetchOnMount: true,
    }
  );

  const { isLoading: dashboardLoadingTable } = useQuery(
    ["ProgressReportDetailsTable", queryParams],
    () => getProgressReportDetails(queryParams),
    {
      onSuccess: (res) => {
        if (res) {
          setTableRows(
            res?.data?.data?.progress.map((items, idx) => ({
              id: idx,
              sno: idx + 1,
              state: items.state,
              indicatorName: items.indicatorName,
              targetInUnits: items.targetInUnits,
              costTarget: items.costTarget,
              progressInUnits: items.progressInUnits,
              costProgress: items.costProgress,
              redirectionKeyValue: items.redirectionKeyValue,
              indicatorId: items.indicatorId,
            }))
          );
        }
      },
      refetchOnMount: true,
    }
  );

  const handleCardClick = (indicatorId: string) => {
    setQueryParams((prevFilter) => {
      const newState = {
        ...prevFilter,
        indicatorIds: indicatorId,
      };
      return newState;
    });
  };

  const user = useAppSelector((state) => state.root.user);

  const progressTableCol =
    user?.data?.userType === null ? "Cost Estimate ID" : "State";
  const dashboardTableData: DashBoardTable = {
    topHeader: ["", "Target", "Progress"],
    header: [
      "S.No",
      progressTableCol,
      "Activity Name",
      "Physical",
      "Financial",
      "Physical",
      "Financial",
    ],
    data: tableRows,
  };

  const styles = getStyles(defaultStyles);

  return (
    <>
      <Box {...styles("topHeader")}>
        <Box sx={{ display: "flex", gap: "8px" }}></Box>
        <Box {...styles("statsCardWrapper", { marginTop: "20px" })}>
          {dashboard?.data?.data?.progress?.map((stat, index) => (
            <ProgressCard
              key={index}
              title={`${stat?.indicatorName?.toUpperCase()}`}
              value={`${stat?.costProgress}`}
              area={stat?.progressInUnits}
              customStyles={{ valueCard: styles("statsCard").sx }}
              id={stat?.indicatorId}
              onClick={handleCardClick}
            />
          ))}
        </Box>
      </Box>
      {dashboardLoadingTable ? (
        <Fallback />
      ) : (
        <Box {...styles("dashboardTableWrapper")}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" {...styles("heading")}>
              Status of Progress Details
            </Typography>
            <Button
              variant="outlined"
              onClick={() => setTableState(!tableState)}
            >
              {tableState ? (
                <>
                  Compress
                  <ArrowUpwardIcon sx={{ marginLeft: "10px" }} />
                </>
              ) : (
                <>
                  Expand
                  <ArrowDownwardIcon sx={{ marginLeft: "10px" }} />
                </>
              )}
            </Button>
          </Box>
          <Collapse in={tableState}>
            <ProgressDetailReport {...dashboardTableData} />
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default ProgressReport;
