import { MyProfileType } from "src/constants/forms";
import dataProvider from "src/dataProvider";
import { ActivityFormType, ProjectInterface } from "src/types/common";

let fetcher = dataProvider("adminInstance");

export const getAllProjects = async (queryParams: any) => {
  return fetcher.get<{ count: number; rows: ProjectInterface[] }>(
    `${"/projects"}`,
    queryParams
  );
};

export const getMyProfileData = async (id?: string) => {
  return fetcher.get(`/user/${id}`);
};

export const getProjectById = async (id: string) => {
  return fetcher.get<ProjectInterface>(`/projects/${id}`);
};

export const getImageById = async (id: string) => {
  return fetcher.get(`/upload/file/${id}`);
};

export const updateProjectStatus = async (id: string, data: any) => {
  return fetcher.patch(`/projects/${id}`, data);
};

export const getAllUser = async () => {
  return fetcher.get(`"/user"`);
};

export const demandDraft = (data: {
  id: string;
  data: {
    demandNote: number;
    grantedLandKmlId: string;
  };
}) => {
  return fetcher.patch<any>(
    `/projects/land-ownership/${data.id}/demand-notes`,
    data.data
  );
};

export const getLandDetailsById = async (id: string) => {
  return fetcher.get<any>(`/projects/land-ownership/${id}`);
};

export type UpdateUserProfile = {
  id: string;
  data: MyProfileType;
};

export const updateUserProfile = async (data: UpdateUserProfile) => {
  return fetcher.put(`/user/${data.id}`, data.data);
};

export const addImplementingOfficerApi = async (formData: any, id: any) => {
  return fetcher.post(`/user/${id}/implementing-officers`, formData);
};

export const editImplementingOfficerApi = async (formData: any, id: any) => {
  return fetcher.put(`/user/implementing-officers/${id}`, formData);
};

export const queryHistory = (data) => {
  return fetcher.patch<any>(`/projects/remark/history`, data);
};

export type UpdateActivityFormType = {
  id: string;
  data: ActivityFormType;
};

export const updateProjectDetails = async (data: UpdateActivityFormType) => {
  return fetcher.put(`/projects/${data.id}`, data.data);
};

export const addProjectToIo = async (
  projectId: string,
  implementingOfficerId: string
) => {
  return fetcher.patch(`/projects/${projectId}/implementing-officer`, {
    implementingOfficerId: implementingOfficerId,
  });
};

export const getDashboardDetails = async () => {
  return fetcher.get<any>(`/projects/dashboard`);
};

export const getTableDashboardDetails = async (queryParams: any) => {
  return fetcher.get<any>(`/projects/dashboard/table`, queryParams);
};

export const getLandDashboardDetails = async () => {
  return fetcher.get<any>(`/projects/land-ownership/dashboard`);
};

export const verifyDemandDraft = (data: {
  id: string;
  data: {
    isVerified: boolean;
  };
}) => {
  return fetcher.patch<any>(
    `/projects/land-ownership/${data.id}/nodal-verify-demand-notes`,
    data.data
  );
};

export const changePassword = async (data: any) => {
  return fetcher.post("/auth/change-password", data);
};

export const fillFundApplicationAPI = (EPid: string, data: any) => {
  return fetcher.post<any>(
    `/projects/land-ownership/${EPid}/fund-request`,
    data
  );
};

export const EditFundApplicationAPI = (EPid: string, data: any) => {
  return fetcher.put<any>(
    `/projects/land-ownership/fund-request/${EPid}`,
    data
  );
};

export const verifyFundApplication = (data: {
  FundApplicationId: string;
  data: {
    status: string;
    remark: string;
    paymentApplicationDocId?: string;
  };
}) => {
  return fetcher.patch<any>(
    `/projects/land-ownership/fund-request/${data.FundApplicationId}/status`,
    data.data
  );
};

export const uploadPaymentReciept = (data: {
  FundApplicationId: string;
  data: {
    status: string;
    paymentAcknowledgeDocId?: string;
  };
}) => {
  return fetcher.patch<any>(
    `/projects/land-ownership/fund-request/${data.FundApplicationId}/status`,
    data.data
  );
};

export const fundApplicationQuery = (data) => {
  return fetcher.post<any>(`/projects/land-ownership/fund-request/query`, data);
};

export const addCostestimateDetails = (data) => {
  return fetcher.post<any>(`/monitoring/target`, data);
};

export const addMointoringProgress = (data) => {
  return fetcher.post<any>(`/monitoring/progress`, data);
};

export const addMointoringMember = (data) => {
  return fetcher.post<any>(`/monitoring/committee/member`, data);
};

export const getMointoringMember = (id) => {
  return fetcher.get<any>(`/monitoring/committee?landOwnershipEpid=${id}`);
};

export const getProgressIndicators = () => {
  return fetcher.get<any>(`/monitoring/indicator`);
};

export const getProgressYear = () => {
  return fetcher.get<any>(`/monitoring/target/year`);
};

export const getProgressActivities = async (id: string) => {
  return fetcher.get<any>(`/monitoring/activity?indicatorId=${id}`);
};

export const deleteCostEstimateDetails = async (id: string) => {
  return fetcher.delete(`/monitoring/target/${id}`);
};

export const updateCostEstimateDataAPI = (id: string, data: any) => {
  return fetcher.patch<any>(`/monitoring/target/${id}`, data);
};

// monitoring/target/status endpoint doesn't store data it just change status, thats why using /monitoring/target
export const updateStatusCostEstimateDetails = (data) => {
  return fetcher.put<any>(`/monitoring/target`, data);
};

export const getProgressDetails = async (id: string) => {
  return fetcher.get<any>(`/monitoring/progress/history`, {
    targetId: id,
  });
};

export const getCostEstimateDetails = async (queryParams: any) => {
  return fetcher.get<any>(`/monitoring/target/land-ownerships`, queryParams);
};

export const getImages = async (id: any) => {
  return fetcher.get<any>(`/monitoring/progress/images`, {
    progressId: id,
  });
};

export const getPlantationDetail = async (id: any) => {
  return fetcher.get<any>(`/monitoring/target/species?landOwnershipEpid=${id}`);
};

export const postMonitoringComponentDetail = async (data: any) => {
  return fetcher.post<any>(`/monitoring`, data);
};

export const getMonitoringComponentData = async (
  component: any,
  queryParams: any
) => {
  return fetcher.get<any>(`/monitoring/${component}`, queryParams);
};

export const getAllMonitoringDetailsData = async (queryParams: any) => {
  return fetcher.get<any>(`/monitoring`, queryParams);
};

export const deleteComponentView = async (id: string) => {
  return fetcher.delete(`/monitoring/${id}`);
};

export const getFinalSubmittedComponentDetails = async (epid: string) => {
  return fetcher.get<any>(`/monitoring/submitted/${epid}`);
};

export const getProgressReportDetails = async (queryParams: any) => {
  return fetcher.get<any>(`/monitoring/progress`, queryParams);
};

export const updateSeedlingRowDetailsData = async (id: string, data: any) => {
  return fetcher.put(`/monitoring/target/seedling/${id}`, data);
};

export const deleteSeedlingRowDetails = async (id) => {
  return fetcher.delete(`/monitoring/target/seedling/${id}`);
};

export const putQueryResponse = async (data: any) => {
  return fetcher.put(`/monitoring/target`, data);
};
