import { createStyles } from "src/styles/theme";

export type StylesClasses = keyof typeof styles;

const styles = createStyles({
  button: {
    width: "5%",
    border: "1px solid blue",
    margin: "1%",
    "&:hover": {
      backgroundColor: "blue",
      color: "white",
    },
  },
  input: {
    margin: "0 0 24px 0",
    flexBasis: { xs: "100%", sm: "75%", lg: "46%" },
  },
  label: {
    position: "relative",
  },
  selectWrapper: {
    minWidth: "50px",
    borderTopRightRadius: "0px",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  renderButtonText: {
    display: "block",
    textAlign: "left",
    fontSize: "17px",
  },
  renderButtonText2: {
    display: "block",
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "600",
    color: "#36B1A0",
    textDecoration: "none",
  },
  formWrapper: {
    margin: "20px 0",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#36B1A0",
    padding: "15px 20px",
    borderRadius: "15px",
    maxWidth: { xs: "100%", lg: "100%" },
    display: "flex",
    backgroundColor: "#EBFAF8",
  },
  documentGroup: {
    display: "flex",
    gap: "2%",
    margin: "10px 0",
  },
  queryContainer: {
    width: "60%",
    border: "1px solid",
    borderColor: "#36B1A0",
    borderRadius: "8px",
    padding: "20px",
  },
  queryTypography: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#36B1A0",
    borderBottom: "1px solid",
    mb: "10px",
    width: "fit-content",
  },
});

export default styles;
