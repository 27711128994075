import { Box, Divider } from "@mui/material";
import { CustomStyles, getStyles } from "src/styles/theme";
import defaultStyles, { StylesClasses } from "./styles";

type StatsCardProps = {
  title: string;
  value: number | string;
  variant?: "horizontal" | "vertical";
  icon?: string;
  area?: string;
  customStyles?: CustomStyles<StylesClasses>;
  onClick?: (id: string) => void;
  id: string;
};

const ProgressCard = ({
  customStyles,
  variant = "horizontal",
  title,
  value,
  area,
  onClick,
  id,
}: StatsCardProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  return (
    <Box {...styles("valueCard")} onClick={() => onClick(id)}>
      <Box {...styles("title")}>{title}</Box>
      <Divider {...styles("divider")} />
      <Box {...styles("iconHalf")}>
        {area && (
          <Box {...styles("value")}>
            <Box {...styles("subHeading")}>Physical Progress</Box>
            {area}
          </Box>
        )}
        <Box {...styles("value")}>
          <Box {...styles("subHeading")}>Financial Progress</Box>
          {value}
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressCard;
