import { AxiosResponse } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DashboardWrapper } from "src/components/common/DashboardWrapper";
import HorizontalTabs from "src/components/common/HorizontalTabs";
import Snackbar from "src/components/common/Snackbar";
import {
  entityStatusMessages,
  entityTabs,
  homeTabs,
  ProjectType,
  registeredLandTabs,
} from "src/constants/common";
import { tableColumns } from "src/constants/tableColumns";
import { resetProjectById } from "src/redux/actions";
import { refetchAllProjects } from "src/redux/slices/dashboard/projects";
import { setIsError, setProjectType } from "src/redux/slices/newProjectSetup";

import { Box } from "@mui/material";
import { Button } from "src/components/common/FormComponents/Buttons";
import { resetProjectForm, setOpen } from "src/redux/slices/newProjectSetup";
import { getStatesData } from "src/redux/slices/state";
import { useAppSelector } from "src/redux/store";
import {
  getDivisionOptions,
  getStateOptions,
  getTreePlantation,
} from "src/utils/api/createProjectFlow";
import { getAllProjects, getMyProfileData } from "src/utils/api/dashboard";
import {
  dateAndTimeStamp,
  downloadSheet,
  filterObject,
  formatToIndianCurrency,
  optionTypeFormatter,
} from "src/utils/helper";
import CreateProject from "../CreateProject";
import {
  RenderButton,
  RenderChip,
  RenderDemandDraft,
  RenderView,
} from "./RenderComponent";
import RenderTable from "./RenderTable";
import SearchBar from "src/components/common/SearchField";
import MultiSelect from "src/components/common/Multiselect";
import { getStates } from "src/utils/api/roles";
import { path } from "src/constants/path";

type filterProjectType = ProjectType.TREE_PLANTATION | ProjectType.LAND_LEASED;

const Mapping: Record<
  "BUTTON" | "CHIP" | "DEMAND_DRAFT" | "RENDER_VIEW",
  (params: any) => JSX.Element
> = {
  BUTTON: RenderButton,
  CHIP: RenderChip,
  DEMAND_DRAFT: RenderDemandDraft,
  RENDER_VIEW: RenderView,
};

const Home = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("currentTab");

  const [activeTab, setActiveTab] = useState(tab ?? undefined);

  const userId = useAppSelector((state) => state.root.user.data.userId);

  const [queryParams, setQueryParams] = useState<any>({
    projectType: "LAND_LEASED",
    offset: 0,
    limit: 50,
    sortBy: "updated_at:DESC",
    status: activeTab,
    download: false,
    searchText: "",
  });

  const [landQueryParams, setLandQueryParams] = useState<any>({
    offset: 0,
    limit: 50,
    download: false,
    sortBy: "updated_at:DESC",
    status: activeTab,
    searchText: "",
  });

  const [projectData, setProjectData] = useState<any>({
    count: 0,
    rows: [],
  });

  const [plantationBlockData, setPlantationBlockData] = useState<any>({
    count: 0,
    rows: [],
  });

  const { isError, msg } = useAppSelector(
    (state) => state.root.selectNewProjectSetup.isError
  );
  const { projectType } = useAppSelector(
    (state) => state.root.selectNewProjectSetup
  );
  const refetchProjects = useAppSelector(
    (state) => state.root.allProjects.refetchProjects
  );

  const [filterKey, setFilterKey] = useState({
    projectStateIds: null,
  });

  const [divsion, setDivson] = useState({
    projectDivsion: null,
  });

  const [stateOption, setStateOptions] = useState([]);

  const user = useAppSelector((state) => state.root.user);

  const { isFetching } = useQuery(
    ["projects", queryParams],
    () =>
      getAllProjects({
        ...queryParams,
      }),
    {
      onSuccess: (res: AxiosResponse) => {
        dispatch(refetchAllProjects(false));

        const rows = res.data.rows.map((row) => ({
          data: row,
          sno: row?.serialNo,
          id: row.id,
          status: row.status,
          division: row?.landSpecification?.division,
          district: row?.address?.districtDetail?.name ?? "-",
          range: row?.landSpecification?.range,
          registrationNo: row.registrationNo,
          totalArea: `${
            (+row?.landSpecification?.totalArea).toFixed(2) ?? "0"
          }`,
          occupiedArea: `${
            (+row?.landSpecification?.occupiedArea).toFixed(2) ?? "0"
          }`,
          areaAvailable: `${
            (row?.landSpecification?.totalArea &&
              row?.landSpecification?.occupiedArea &&
              (
                +row?.landSpecification?.totalArea -
                +row?.landSpecification?.occupiedArea
              ).toFixed(2)) ??
            "-"
          }`,
          initiatedBy:
            row?.organizationAffiliationDetail?.userDetail?.firstName,
          updated_at: dateAndTimeStamp(row?.updated_at, "IST"),
          created_at: dateAndTimeStamp(row?.created_at, "IST"),
          remark: row.remarks,
        }));

        if (queryParams.download) {
          downloadSheet(rows);
          setQueryParams((prev) => ({
            ...prev,
            download: false,
          }));
        } else {
          setProjectData({
            count: res.data.count,
            rows,
          });
        }
      },
      refetchOnMount: true,
      enabled: queryParams.projectType === "LAND_LEASED",
    }
  );

  const { isFetching: isPlantationFetching, refetch: plantationRefetch } =
    useQuery(
      ["plantationProjects", landQueryParams],
      () =>
        getTreePlantation({
          ...landQueryParams,
        }),
      {
        onSuccess: (res: AxiosResponse) => {
          const rows = res.data.rows.map((row) => ({
            sno: row?.serialNo,
            id: row.id,
            registrationNo: row.registrationNo,
            entityName: row.user.firstName,
            state: row.state ?? "-",
            district: row?.district ?? "-",
            division: row?.projectDetail?.landSpecification?.division,
            totalArea: `${row.totalArea}`,
            finalArea: row?.netPlantationArea ?? "-",
            ecoRestorationCost: ![
              "REGENERATE_DEMAND_NOTE",
              "UNDER_QUERY",
              "GENERATE_DEMAND_NOTE",
              "VERIFY_DEMAND_NOTE",
              "NODAL_UNDER_QUERY",
              "NODAL_VERIFY_DEMAND_NOTE",
            ].includes(row.status)
              ? formatToIndianCurrency(row?.demandNote)
              : "-",
            adminCharges: ![
              "REGENERATE_DEMAND_NOTE",
              "UNDER_QUERY",
              "GENERATE_DEMAND_NOTE",
              "VERIFY_DEMAND_NOTE",
              "NODAL_UNDER_QUERY",
              "NODAL_VERIFY_DEMAND_NOTE",
            ].includes(row.status)
              ? formatToIndianCurrency(row?.icfreCharges)
              : "-",
            demandNote: ![
              "REGENERATE_DEMAND_NOTE",
              "UNDER_QUERY",
              "GENERATE_DEMAND_NOTE",
              "VERIFY_DEMAND_NOTE",
              "NODAL_UNDER_QUERY",
              "NODAL_VERIFY_DEMAND_NOTE",
            ].includes(row.status)
              ? row.demandNote
                ? formatToIndianCurrency(
                    +`${Number(row?.demandNote)}` +
                      +`${Number(row?.icfreCharges)}`
                  )
                : "-"
              : "-",
            totalAmountPaid: formatToIndianCurrency(row.amountPaid) ?? "-",
            ecoRestorationCostPaid:
              formatToIndianCurrency(row.ecoRestorationCost) ?? "-",
            adminChargesPaid: formatToIndianCurrency(row.adminCharges) ?? "-",
            status: entityStatusMessages[row.status] ?? row.status,
            created_at: dateAndTimeStamp(row?.created_at, "IST"),
            updated_at: dateAndTimeStamp(row?.updated_at, "IST"),
          }));

          if (landQueryParams.download) {
            downloadSheet(rows);
            setLandQueryParams((prev) => ({
              ...prev,
              download: false,
            }));
          } else {
            setPlantationBlockData({
              count: res.data.count,
              homeTabs,
              rows,
            });
          }
        },
        refetchOnMount: true,
        // enabled:
        //   refetchProjects && queryParams.projectType === "TREE_PLANTATION",
      }
    );

  const { data: myProfileData } = useQuery(
    ["myProfile", userId],
    () => getMyProfileData(userId),
    {
      onSuccess: () => {},
      onError: () => {},
      enabled: !!userId,
      refetchOnMount: true,
    }
  );

  useQuery("state", () => getStateOptions(), {
    onSuccess: (res) => {
      dispatch(getStatesData(res.data));
    },
    refetchOnMount: true,
  });

  const { data } = useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStateOptions(data.data);
    },
    refetchOnMount: true,
  });

  const onSideBarClose = () => {
    dispatch(resetProjectForm({}));
    dispatch(resetProjectById());
  };

  useEffect(() => {
    onSideBarClose();
    dispatch(refetchAllProjects(true));
  }, [queryParams, dispatch]);

  const columns = useMemo(
    () =>
      tableColumns
        .map((item) => ({
          ...item,
          renderCell: Mapping[item.renderCell],
        }))
        .filter((item) => {
          return (
            item.type.includes(queryParams.projectType) &&
            item.userType.includes(user.data.userType)
          );
        }),
    [queryParams.projectType]
  );

  const handlePagination = (page: any) => {
    dispatch(refetchAllProjects(true));
    setQueryParams({
      ...queryParams,
      offset: queryParams.limit * page,
    });
  };

  const handleEntityPagination = (page: any) => {
    setLandQueryParams({
      ...landQueryParams,
      offset: landQueryParams.limit * page,
    });
  };

  const handleSort = (sortByField) => {
    setQueryParams({
      ...queryParams,
      sortBy: `${sortByField[0]?.field}:${sortByField[0]?.sort.toUpperCase()}`,
    });
  };

  const handleEntitySort = (sortByField) => {
    setLandQueryParams({
      ...landQueryParams,
      sortBy: `${sortByField[0]?.field}:${sortByField[0]?.sort.toUpperCase()}`,
    });
  };

  useEffect(() => {
    if (queryParams.projectType !== projectType) {
      dispatch(setProjectType(queryParams.projectType));
    }
  }, [dispatch, projectType, queryParams]);

  useEffect(() => {
    if (user.data.role == "Entity/ Green Credit Applicant") {
      dispatch(setProjectType("TREE_PLANTATION"));
      setQueryParams({
        projectType: "TREE_PLANTATION",
        offset: 0,
        limit: 100,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportToExcel = () => {
    setQueryParams({
      ...queryParams,
      download: true,
    });
  };

  const exportToExcelEntity = () => {
    setLandQueryParams({
      ...queryParams,
      download: true,
    });
  };

  const district = useMemo(() => {
    const d = myProfileData?.data?.currentAddress?.state; // id, name
    const dist =
      stateOption.find((i) => i.id === d) ||
      stateOption.find((i) => i.name === d);
    return dist?.districts.length ? optionTypeFormatter(dist?.districts) : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myProfileData?.data?.currentAddress?.state, stateOption]);

  const { data: division } = useQuery(
    ["divisionsOption", user.data.stateId],
    () => getDivisionOptions(user.data.stateId),
    {
      enabled: !!user.data.stateId,
    }
  );

  // const divisionoptions = optionTypeFormatter(division?.data?.rows);

  const divisionoptions2 = division?.data?.rows?.map((item) => {
    return {
      id: item.name,
      label: item.name,
    };
  });

  return (
    <DashboardWrapper
      title={
        user.data.role === "Implementing Agency"
          ? "Registered Eco-Restoration Blocks"
          : "Eco-Restoration Portfolio"
      }
      buttonIcon="/assets/svg/roundPlusIcon.svg"
      buttonText={
        user.data.role === "Implementing Agency"
          ? "Register New Eco-Restoration Block"
          : " Select Eco-Restoration Block"
      }
      onButtonClick={() => {
        dispatch(setOpen());
      }}
      showBtn={user.data.userType !== "INDIVIDUAL"}
    >
      <CreateProject
        onSideBarClose={onSideBarClose}
        refetch={plantationRefetch}
      />
      <>
        {user.data.role === "Entity/ Green Credit Applicant" ? (
          <Box>
            <SearchBar
              getText={(e) => {
                setLandQueryParams((a) => {
                  return { ...landQueryParams, searchText: `${e}` };
                });
              }}
              customStyles={{
                wrapper: {
                  width: "80%",
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                gap: "13%",
                padding: "10px 1%",
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              <HorizontalTabs
                tabHeaders={entityTabs}
                tabIndex={entityTabs.findIndex(
                  (item) => item.value === activeTab
                )}
                onChange={(e: filterProjectType) => {
                  dispatch(refetchAllProjects(true));
                  setActiveTab(e);
                  navigate(`${path.home}${e ? `?currentTab=${e}` : ""}`);
                  setLandQueryParams({
                    ...landQueryParams,
                    status: e,
                    offset: 0,
                  });
                }}
                components={[<></>, <></>]}
              />
              <Button onClick={exportToExcelEntity} text={"Export"} />
            </Box>
            <RenderTable
              rows={plantationBlockData.rows}
              columns={columns}
              hideFooterPagination={false}
              handleSortModelChange={handleEntitySort}
              isLoading={isPlantationFetching}
              rowCount={plantationBlockData?.count / landQueryParams.limit}
              onPageChange={(page) => handleEntityPagination(page)}
              pageNumber={landQueryParams.offset / landQueryParams.limit + 1}
            />
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <SearchBar
                getText={(e) => {
                  setQueryParams((a) => {
                    return { ...queryParams, searchText: `${e}` };
                  });
                }}
                customStyles={{
                  wrapper: {
                    width: "80%",
                  },
                }}
              />
              {user.data.userType === "INDIVIDUAL" && (
                <>
                  <MultiSelect
                    options={district}
                    text={"District"}
                    onChange={(e: any) => {
                      setFilterKey((prevFilter) => {
                        const newState = {
                          ...prevFilter,
                          projectDistrictIds: e.length ? e : null,
                        };

                        setQueryParams({
                          ...queryParams,
                          projectDistrictIds: newState?.projectDistrictIds,
                        });

                        return newState;
                      });
                    }}
                  />

                  <MultiSelect
                    options={divisionoptions2}
                    text={"Division"}
                    onChange={(e: any) => {
                      setDivson((prevFilter) => {
                        const newState = {
                          ...prevFilter,
                          projectDivisions: e.length ? e : null,
                        };

                        setQueryParams({
                          ...queryParams,
                          projectDivisions: newState?.projectDivisions,
                        });

                        return newState;
                      });
                    }}
                  />
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                gap: "13%",
                padding: "10px 1%",
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
            >
              <HorizontalTabs
                tabHeaders={registeredLandTabs}
                tabIndex={registeredLandTabs.findIndex(
                  (item) => item.value === activeTab
                )}
                onChange={(e: filterProjectType) => {
                  dispatch(refetchAllProjects(true));
                  navigate(`${path.home}${e ? `?currentTab=${e}` : ""}`);
                  setActiveTab(e);
                  setQueryParams({
                    ...queryParams,
                    status: e,
                    offset: 0,
                  });
                }}
                components={[<></>, <></>]}
              />

              <Button onClick={exportToExcel} text={"Export"} />
            </Box>

            <RenderTable
              rows={projectData.rows}
              columns={columns}
              handleSortModelChange={handleSort}
              hideFooterPagination={false}
              isLoading={isFetching}
              rowCount={projectData?.count / queryParams.limit}
              onPageChange={(page) => handlePagination(page)}
              pageNumber={queryParams.offset / queryParams.limit + 1}
            />
          </>
        )}
      </>
      <Snackbar
        message={msg ? msg : "Something Went Wrong"}
        open={isError}
        severity="error"
        duration={2000}
        resetSnackbar={() =>
          dispatch(
            setIsError({
              isError: false,
              msg: "",
            })
          )
        }
      />
    </DashboardWrapper>
  );
};

export default Home;
