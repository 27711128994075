import { Box, Grid, Typography } from "@mui/material";
import {
  Button,
  DateInput,
  FileUpload,
  Input,
} from "../../../common/FormComponents";
import defaultStyles, { StylesClasses } from "./styles";
import { getStyles } from "src/styles/theme";
import { useForm } from "react-hook-form";
import errorMessage from "src/constants/errorMessage";
import { useMutation, useQuery } from "react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  addMointoringProgress,
  getProgressDetails,
} from "src/utils/api/dashboard";
import { ProgressHistory } from "src/components/common/DashboardTable/progressHistory";
import { useAppSelector } from "src/redux/store";

export function AddProgress({ rowData, selfState, refetch }: any) {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const user = useAppSelector((state) => state.root.user);
  console.log("row data", rowData);
  const { data: progressDetails } = useQuery(
    ["userDetails", rowData.id],
    () => getProgressDetails(rowData.id),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      refetchOnMount: "always", // Refetches every time the component mounts
      refetchOnWindowFocus: true,
    }
  );

  const { mutate, isLoading } = useMutation(
    "postUpdatedCostEstimateData",
    ({ updateValue }: { updateValue: any }) =>
      addMointoringProgress(updateValue),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log("data updated successfully");
        selfState(false);
        refetch();
      },
      onError: (error: any) => {
        console.log("error occured while updating");
      },
    }
  );

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,

    formState: { errors, isValid },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      cost: 0,
      quantity: 0,
    },
  });

  const onSubmit = (data: any) => {
    const imageIds = data.geoTaggedImages.map((image: any) => image.key);
    mutate({
      updateValue: {
        ...data,
        quantityInNumber: +data.quantity,
        cost: +data.cost,
        landOwnershipId: rowData.costEstimateID,
        targetId: rowData.id,
        fromDate: new Date(data.fromDate),
        toDate: new Date(data.toDate),
        images: imageIds,
      },
    });
  };
  const commonTableHeaders = [
    "Updated Date",
    "From Date",
    "To Date",
    `Physical Progress (${progressDetails?.data?.data?.physicalProgressUnit})`,
    "Financial Progress (₹)",
    "View GeoTagged Photos",
  ];

  return (
    <Box {...styles("wrapper")}>
      <Box {...styles("heading")}>Progress History</Box>
      <Box sx={{ alignSelf: "start", mt: "20px", mb: "30px" }}>
        <Box {...styles("componentName")}>
          <Typography>Main Component&nbsp;:&nbsp;</Typography>
          <Typography>{rowData.mainComponent}</Typography>
        </Box>
        <Box {...styles("componentName")}>
          <Typography>Sub Component&nbsp;:&nbsp;</Typography>
          <Typography>{rowData.subComponent}</Typography>
        </Box>
        <Box {...styles("componentName")}>
          <Typography>Particulars of item of works&nbsp;:&nbsp;</Typography>
          <Typography>{rowData.perticularOfItem}</Typography>
        </Box>
      </Box>
      <ProgressHistory
        topHeader={commonTableHeaders}
        data={progressDetails?.data?.data?.progress?.rows || []}
      />
      {user?.data?.userType === null && (
        <Box
          {...styles("formWrapper")}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box {...styles("heading")}>Upload New Progress</Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DateInput
                name="fromDate"
                label="From date"
                control={control}
                // disabled={disable}
                errors={errors}
                max={new Date("10/12/3000")}
                minAge={0}
                rules={{ required: errorMessage.required }}
                clearErrors={clearErrors}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateInput
                name="toDate"
                label="To date"
                control={control}
                // disabled={disable}
                errors={errors}
                max={new Date("10/12/3000")}
                minAge={0}
                rules={{ required: errorMessage.required }}
                clearErrors={clearErrors}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                  input: {
                    borderColor: "#36B1A0;",
                    boxShadow: "unset",
                  },
                }}
                name="quantity"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Enter Physical Progress"
                label="Physical Progress"
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                  input: {
                    borderColor: "#36B1A0;",
                    boxShadow: "unset",
                  },
                }}
                name="cost"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="Enter Financial Progress"
                label="Financial Progress(Rs.)"
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FileUpload
                name="geoTaggedImages"
                label="Upload Geo Tagged Photos"
                control={control}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                setError={setError}
                clearErrors={clearErrors}
                maxLimit={3}
                required={true}
                trigger={trigger}
                customStyles={{
                  wrapper: defaultStyles.borderStyle,
                  fileType: defaultStyles.fileType,
                  label: {
                    whiteSpace: "wrap",
                  },
                }}
                rules={{
                  validate: {
                    isLength: (value) => {
                      return value && value["length"] >= 1;
                    },
                  },
                }}
                dragDropHeading=""
              />
            </Grid>
          </Grid>
          <Box>
            <Button
              text="update"
              type="submit"
              disabled={!isValid}
              loading={isLoading}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
