import { Box, CircularProgress, Divider } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  AutoComplete,
  Button,
  FileUpload,
  Input,
} from "src/components/common/FormComponents";
import MapComponent from "src/components/common/KML";
import SuccessModal from "src/components/common/modal/Success";
import ViewForm from "src/components/common/ViewForm";
import ViewImages from "src/components/common/ViewImage";
import { devTypes, PROJECT_DOCUMENT_TYPE_MAP } from "src/constants/common";
import errorMessage from "src/constants/errorMessage";
import { useAppSelector } from "src/redux/store";
import { CustomStyles, getStyles } from "src/styles/theme";
import { ActivityFormType, ProjectInterface } from "src/types/common";
import {
  addProjectToIo,
  getMyProfileData,
  getProjectById,
  queryHistory,
  UpdateActivityFormType,
  updateProjectDetails,
  updateProjectStatus,
} from "src/utils/api/dashboard";
import { dateAndTimeStamp, downloadSheet } from "src/utils/helper";
import defaultStyles, { StylesClasses } from "../../styles";
import { defaultForm } from "../defaultForm";
import Card from "src/components/common/ImageCard";
import Checkbox from "src/components/common/FormComponents/Checkbox";

type LandLeaseDetailsProps = {
  projectId: string;
  customStyles?: CustomStyles<StylesClasses>;
};

const LandLeaseDetails = ({
  projectId,
  customStyles,
}: LandLeaseDetailsProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const [projectData, setProjectData] = useState<ProjectInterface>(null);
  const [documents, setDocuments] = useState<any>();

  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "",
    subheading: "",
    description: "",
    btnText: "",
    type: "",
  });

  const [derivedArray, setDerivedArray] = useState([]);

  const user = useAppSelector((state) => state.root.user);

  const role = user?.data.userType;

  const { isLoading } = useQuery(
    ["projects", projectId],
    () => getProjectById(projectId),
    {
      onSuccess: (res: AxiosResponse) => {
        setProjectData(res.data);
        const groupDocTypes = _.groupBy(res?.data?.documentDetail, "type");
        setDocuments(groupDocTypes);
      },
      onError: (err: AxiosError) => {
        setProjectData(null);
      },
      refetchOnMount: true,
    }
  );

  const userId = projectData?.organizationAffiliationDetail?.userId;
  const projectDetailID = projectData?.id;

  const { data: userData, isLoading: isProjectDetailLoading } = useQuery(
    ["userDetails", userId],
    () => getMyProfileData(userId),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      enabled: !!userId,
    }
  );
  const data = userData?.data?.implementingOfficerDetails?.map((item) => {
    return {
      value: item.id,
      label: item.division,
    };
  });

  const { mutate: mutateIOQueryData } = useMutation(
    "query",
    ({
      projectId,
      query,
      type,
      underQuery,
    }: {
      projectId: string;
      query: string;
      type: string;
      underQuery: string;
    }) =>
      queryHistory({
        projectId,
        query,
        type,
        underQuery,
      }),
    {
      onSuccess: () => {
        setValue("query", "");
        navigate("/");
      },
      onError: () => {},
    }
  );

  const { mutate: submitMutate } = useMutation(
    "changeStatus",
    (data: any) => updateProjectStatus(projectId, data),
    {
      onSuccess: (res: AxiosResponse) => {
        navigate("/");
      },
      onError: (err: AxiosError) => {},
    }
  );

  const { mutate: mutateAddIO } = useMutation(
    "AddIO",
    ({
      projectId,
      implementingOfficerId,
    }: {
      projectId: string;
      implementingOfficerId: string;
    }) => addProjectToIo(projectId, implementingOfficerId),
    {
      onSuccess: () => {
        navigate("/");
      },
      onError: () => {},
    }
  );

  const {
    control,
    formState,
    reset,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    clearErrors,
    setError,
  } = useForm<ActivityFormType>({
    defaultValues: {
      ...defaultForm,
      landFreeFromEncumbrances: true,
      landSuitableForPlantation: true,
    },
  });
  const { errors, isValid } = formState;

  useEffect(() => {
    if (!!projectData) {
      reset({
        name: "Land Based Project",
        isEditable: false,
        projectType: "LAND_LEASED",
        landTypeId: "419bff70-3735-4627-b378-269f5e02f247",
        areaUnit: "ha",
        landArea: Number(projectData?.landArea),
        address: {
          state: projectData?.address?.state,
          district: projectData?.address?.district,
          khasraNo: projectData?.address?.khasraNo,
        },
        landSpecification: {
          edaphicDetails: projectData?.landSpecification?.edaphicDetails,
          slopeAndTerrain: projectData?.landSpecification?.slopeAndTerrain,
          hydrologicalConditions:
            projectData?.landSpecification?.hydrologicalConditions,
          forestType: projectData?.landSpecification?.forestType,
          geologicalFeatures:
            projectData?.landSpecification?.geologicalFeatures,
          divisionId: projectData?.landSpecification?.divisionId,
          division: projectData?.landSpecification?.division,
          range: projectData?.landSpecification?.range,
          compartmentNumber: Number(
            projectData?.landSpecification?.compartmentNumber
          ),
          beat: projectData?.landSpecification?.beat,
          treeCanopyDensity: +projectData?.landSpecification?.treeCanopyDensity,
          description: projectData?.landSpecification.description,
          grossArea: projectData?.landSpecification.grossArea,
        },
        geoTaggedImages: projectData?.images.map((image) => ({
          file: image?.imageUrl?.fileUrl,
          key: image?.imageUrl?.id,
          type: "default",
        })),
        type: "PROJECT",
        documentDetail: [
          {
            documentUrl: "86f743c3-62e3-44b0-97e9-7ebc2d1a47c4",
            type: "LAND_KML_FILE",
            key: "",
          },
        ],
      });
    }
  }, [projectData]);

  const updateProject = useMutation(
    ["signUp"],
    (data: UpdateActivityFormType) => updateProjectDetails(data),
    {
      onSuccess: (res: AxiosResponse) => {
        setOpenModal({
          open: true,
          heading: "",
          subheading: "",
          description: "Project Details Updated successfully",
          btnText: "",
          type: "success",
        });
      },
      onError: (err: AxiosError) => {},
    }
  );

  const handleFormSubmit = (data: ActivityFormType) => {
    updateProject.mutate({
      id: projectDetailID,
      data: {
        ...data,
        landArea: +data.landArea,
        landSpecification: {
          ...data.landSpecification,
          compartmentNumber: +data.landSpecification.compartmentNumber,
          treeCanopyDensity: +data.landSpecification.treeCanopyDensity,
          totalArea: +data.landArea,
          grossArea: +data?.landSpecification.grossArea,
        },
        kmlDocID: data?.kmlDocID?.[0]?.key,
        images: data?.geoTaggedImages.map((item) => item.key),
      },
    });
  };

  const handleResponseModal = () => {
    if (openModal.type === "success") {
      navigate("/");
    } else {
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setOpenModal({ ...openModal, open: false });
    navigate("/");
  };

  useEffect(() => {
    const extractedData = projectData?.projectQuery.map((item) => ({
      Name: item.user.firstName,
      Query: item.query,
      created_at: dateAndTimeStamp(item.created_at, "IST"),
    }));
    setDerivedArray(extractedData);
  }, [projectData]);

  const exportQuery = () => {
    downloadSheet(derivedArray);
  };

  const disable = !(
    (projectData?.status === "UNDER_QUERY" ||
      projectData?.status === "NODAL_UNDER_QUERY") &&
    user.data.userType !== "INDIVIDUAL"
  );

  return (
    <>
      <Box {...styles("projectName")}>
        Eco-Restoration Block Registration No: {projectData?.registrationNo}
      </Box>

      <Box component={"form"} onSubmit={handleSubmit(handleFormSubmit)}>
        <Box>
          {!isLoading && !!projectData ? (
            <>
              <Box {...styles("userDetails")}>
                <Box {...styles("heading")}>
                  {userData?.data?.userType === devTypes.ORGANIZATION
                    ? "Entity Details"
                    : "Implementing Officer Details"}
                </Box>
                <Box {...styles("container")}>
                  <Box {...styles("input")}>
                    <Input
                      name="userInfo.name"
                      label={
                        userData?.data?.userType === devTypes.ORGANIZATION
                          ? "Entity Name"
                          : "Name"
                      }
                      placeholder=""
                      type="text"
                      readOnly={true}
                      value={
                        userData?.data?.userType === devTypes.ORGANIZATION
                          ? userData?.data?.firstName
                          : userData?.data?.firstName
                      }
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="userInfo.email"
                      label="Email"
                      placeholder=""
                      type="text"
                      readOnly={true}
                      value={userData?.data?.email}
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="userInfo.phoneNumber"
                      label="Contact Number"
                      placeholder=""
                      type="text"
                      readOnly={true}
                      value={`${
                        !!userData?.data?.countryCode
                          ? userData?.data?.countryCode
                          : "+91"
                      } ${userData?.data?.phoneNumber}`}
                      control={control}
                    />
                  </Box>
                </Box>
              </Box>
              <Box {...styles("userDetails")}>
                <Box {...styles("heading")}>
                  Proposed Eco-Restoration Block Details
                </Box>
                <Box {...styles("container")}>
                  <Box {...styles("input")}>
                    <Input
                      name="landType"
                      label="Land Type"
                      placeholder=""
                      type="text"
                      readOnly={true}
                      value={projectData?.landType}
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="address.state"
                      label="State"
                      placeholder=""
                      type="text"
                      readOnly={true}
                      value={projectData?.address?.stateName}
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="address.district"
                      label="District"
                      placeholder=""
                      type="text"
                      readOnly={true}
                      value={projectData?.address?.districtName}
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="landSpecification.divisionId"
                      label="Division"
                      placeholder=""
                      type="text"
                      readOnly={true}
                      value={projectData?.landSpecification?.division}
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="landSpecification.range"
                      label="Range"
                      placeholder=""
                      type="text"
                      readOnly={disable}
                      control={control}
                    />
                  </Box>
                  {/* <Box {...styles("input")}>
                    <Input
                      name="landSpecification.grossArea"
                      label="Gross Area"
                      placeholder=""
                      type="number"
                      readOnly={disable}
                      control={control}
                      endAdornment={<Box>{`${projectData?.areaUnit}`}</Box>}
                    />
                  </Box> */}
                  <Box {...styles("input")}>
                    <Input
                      name="landArea"
                      label="Total Land Area"
                      placeholder=""
                      type="number"
                      readOnly={disable}
                      control={control}
                      endAdornment={<Box>{`${projectData?.areaUnit}`}</Box>}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="address.khasraNo"
                      label="Survey No"
                      placeholder=""
                      type="text"
                      readOnly={disable}
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="landSpecification.compartmentNumber"
                      label="Compartment Number"
                      placeholder=""
                      type="number"
                      readOnly={disable}
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="landSpecification.beat"
                      label="Beat"
                      placeholder=""
                      type="text"
                      readOnly={disable}
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="landSpecification.treeCanopyDensity"
                      label="Tree Canopy Density"
                      placeholder=""
                      type="number"
                      readOnly={disable}
                      control={control}
                    />
                  </Box>
                </Box>
              </Box>
              <Box {...styles("userDetails")}>
                <Box {...styles("heading")}>Land Details</Box>
                <Box {...styles("container")}>
                  <Box {...styles("input")}>
                    <Input
                      name="landSpecification.edaphicDetails"
                      label="Edaphic Details"
                      customStyles={{
                        label: defaultStyles.label,
                        wrapper: defaultStyles.selectWrapper,
                        input: defaultStyles.multilineInput,
                      }}
                      multiline
                      minRows={6}
                      placeholder=""
                      type="text"
                      readOnly={disable}
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="landSpecification.slopeAndTerrain"
                      label="Slope And Terrain"
                      placeholder=""
                      customStyles={{
                        label: defaultStyles.label,
                        wrapper: defaultStyles.selectWrapper,
                        input: defaultStyles.multilineInput,
                      }}
                      multiline
                      minRows={6}
                      type="text"
                      readOnly={disable}
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="landSpecification.geologicalFeatures"
                      label="Geological Features"
                      placeholder=""
                      customStyles={{
                        label: defaultStyles.label,
                        wrapper: defaultStyles.selectWrapper,
                        input: defaultStyles.multilineInput,
                      }}
                      multiline
                      minRows={6}
                      type="text"
                      readOnly={disable}
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="landSpecification.forestType"
                      label="Forest Type"
                      customStyles={{
                        label: defaultStyles.label,
                        wrapper: defaultStyles.selectWrapper,
                        input: defaultStyles.multilineInput,
                      }}
                      multiline
                      minRows={6}
                      placeholder=""
                      type="text"
                      readOnly={disable}
                      control={control}
                    />
                  </Box>
                  <Box {...styles("input")}>
                    <Input
                      name="landSpecification.hydrologicalConditions"
                      label="Hydrological Conditions"
                      customStyles={{
                        label: defaultStyles.label,
                        wrapper: defaultStyles.selectWrapper,
                        input: defaultStyles.multilineInput,
                      }}
                      multiline
                      minRows={6}
                      placeholder=""
                      type="text"
                      readOnly={disable}
                      control={control}
                    />
                  </Box>
                </Box>
              </Box>
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>.KML File of Plantation Block</Box>
                {Object.keys(documents)?.map((doc) => (
                  <>
                    <Box>{PROJECT_DOCUMENT_TYPE_MAP[doc]}</Box>
                    <Box {...styles("documentGroup")}>
                      {documents[doc]?.map((item) => (
                        <ViewImages
                          fileUrl={item.documentUrl.fileUrl}
                          label={""}
                        />
                      ))}
                    </Box>
                  </>
                ))}
                {!isProjectDetailLoading && !isLoading && (
                  <MapComponent
                    kmlUrl={
                      projectData?.documentDetail[0]?.documentUrl?.fileUrl
                    }
                  />
                )}
              </Box>
              {!disable && (
                <Box sx={{ width: "60%" }}>
                  <FileUpload
                    name="kmlDocID"
                    label="Please upload GPS Boundary (KML file) of land parcel"
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                    setError={setError}
                    clearErrors={clearErrors}
                    maxLimit={1}
                    supportedFormatMessage="Please ensure that the KML file should contain closed polygon of land parcel and area of polygon in KML file should not less than the area filled in the form"
                    trigger={trigger}
                    // rules={{
                    //   validate: {
                    //     isLength: (value) => {
                    //       return value && value["length"] >= 1;
                    //     },
                    //   },
                    // }}
                    dragDropHeading=""
                  />
                </Box>
              )}

              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>GeoTagged Images</Box>
                <Box {...styles("imageWrapper2")}>
                  {projectData?.images.length ? (
                    <>
                      {projectData?.images?.map((item) => (
                        <Card
                          key={item?.imageUrl?.fileUrl}
                          title={`GeoTagged Image`}
                          image={item?.imageUrl?.fileUrl}
                        />
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              {!disable && projectData && (
                <Box sx={{ width: "60%" }}>
                  <FileUpload
                    key={watch("geoTaggedImages")[0]?.key}
                    accept="image/jpeg, image/png, image/webp, image/jpg"
                    name="geoTaggedImages"
                    label="Upload More GeoTagged Images"
                    control={control}
                    disabled={disable}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                    setError={setError}
                    required={true}
                    clearErrors={clearErrors}
                    maxLimit={10}
                    multiple={true}
                    supportedFormatMessage="Supported formats: .jpeg, .png, .webp, .jpg, Minimum 2 Images required"
                    trigger={trigger}
                    rules={{
                      validate: {
                        isLength: (value) => {
                          return value && value["length"] >= 2;
                        },
                      },
                    }}
                    dragDropHeading=""
                  />
                </Box>
              )}
              {projectData?.landSpecification?.description && (
                <Box {...styles("input")}>
                  <Input
                    customStyles={{
                      label: defaultStyles.label,
                      wrapper: defaultStyles.selectWrapper,
                      input: defaultStyles.multilineInput,
                    }}
                    name="landSpecification.description"
                    rules={{
                      maxLength: {
                        value: 500,
                        message: "More then 500 characters are not allowed",
                      },
                    }}
                    control={control}
                    readOnly={disable}
                    type="text"
                    placeholder="(In 500 characters)"
                    label="Additional Information"
                    multiline
                    minRows={6}
                  />
                </Box>
              )}
              <Box {...styles("formWrapper")}>
                <Box {...styles("heading")}>Forest Department Undertaking</Box>
                <Checkbox
                  name="landFreeFromEncumbrances"
                  control={control}
                  label={"The land is free from all types of encumbrances"}
                  rules={{
                    required: true,
                  }}
                  disabled
                />
                <Checkbox
                  name="landSuitableForPlantation"
                  control={control}
                  label={"The land is suitable for Eco-Restoration"}
                  rules={{
                    required: true,
                  }}
                  disabled
                />
              </Box>

              {projectData?.projectQuery.length > 0 && (
                <Box {...styles("formWrapper")}>
                  <Box {...styles("infoContainer")}>
                    <Button
                      onClick={exportQuery}
                      text={""}
                      variant="outlined"
                      src="/assets/svg/export.svg"
                      customStyles={{
                        root: {
                          minWidth: "40px",
                        },
                      }}
                    />
                    <Box {...styles("heading")}> Query</Box>
                  </Box>
                  {projectData?.projectQuery.map((item) => (
                    <Box sx={{ paddingTop: "20px" }}>
                      <ViewForm
                        label={item.user.firstName}
                        value={item.query}
                        date={dateAndTimeStamp(item.created_at, "IST")}
                      />
                    </Box>
                  ))}
                </Box>
              )}
              {projectData?.snoRemarks && (
                <Box {...styles("input")}>
                  <Input
                    name="userInfo.email"
                    label="SNO Remarks"
                    placeholder=""
                    type="text"
                    readOnly={true}
                    value={projectData?.snoRemarks}
                    control={control}
                  />
                </Box>
              )}
              {(projectData?.status === "UNDER_QUERY" ||
                projectData?.status === "NODAL_VERIFICATION_PENDING" ||
                projectData?.status === "NODAL_UNDER_QUERY") && (
                <Box {...styles("input")}>
                  <Input
                    customStyles={{
                      label: defaultStyles.label,
                      wrapper: defaultStyles.selectWrapper,
                      input: defaultStyles.multilineInput,
                    }}
                    name="query"
                    rules={{
                      required: errorMessage.required,
                      maxLength: {
                        value: 500,
                        message: "More then 500 characters are not allowed",
                      },
                    }}
                    control={control}
                    type="text"
                    placeholder="(In 500 characters)"
                    label="Query"
                    multiline
                    minRows={6}
                  />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItem: "center",
                  padding: "10px",
                }}
              >
                {(projectData?.status === "UNDER_QUERY" ||
                  projectData?.status === "NODAL_VERIFICATION_PENDING" ||
                  projectData?.status === "NODAL_UNDER_QUERY") &&
                  user.data.userType === "INDIVIDUAL" && (
                    <Button
                      text="Raise Query"
                      onClick={() => {
                        mutateIOQueryData({
                          projectId: projectId,
                          query: watch("query"),
                          type: "PROJECT",
                          underQuery: "UNDER_QUERY",
                        });
                      }}
                      disabled={!watch("query")}
                      customStyles={{
                        root: {
                          fontSize: "16px",
                          bottom: "10px",
                        },
                      }}
                    />
                  )}

                {!disable &&
                  projectData?.status !== "NODAL_VERIFICATION_PENDING" &&
                  user.data.userType !== "INDIVIDUAL" && (
                    <Button
                      text={"Submit"}
                      type="submit"
                      disabled={!isValid}
                      customStyles={{
                        root: {
                          fontSize: "16px",
                          bottom: "10px",
                        },
                      }}
                    />
                  )}
              </Box>
              <SuccessModal
                open={openModal.open}
                heading={openModal.heading}
                subheading={openModal.subheading}
                description={openModal.description}
                btnText={openModal.btnText}
                type={openModal.type}
                onClick={() => handleResponseModal()}
                handleModalClose={() => handleModalClose()}
              ></SuccessModal>
            </>
          ) : (
            <Box {...styles("loading")}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
      {projectData?.status === "NODAL_VERIFICATION_PENDING" &&
        role === "INDIVIDUAL" && (
          <>
            <Box {...styles("input")}>
              <Input
                customStyles={{
                  label: defaultStyles.label,
                  wrapper: defaultStyles.selectWrapper,
                }}
                name="snoremarks"
                rules={{ required: errorMessage.required }}
                control={control}
                errors={errors}
                placeholder="SNO Remarks"
                label="SNO Remarks"
              />
            </Box>
            <Box {...styles("btnWrapper")}>
              <Button
                text="Reject"
                variant="outlined"
                onClick={() => {
                  submitMutate({
                    projectId,
                    status: "REJECTED",
                    remarks: watch("snoremarks"),
                  });
                }}
                customStyles={{
                  root: {
                    fontSize: "18px",
                    color: "error.main",
                    borderColor: "error.main",
                  },
                }}
              />
              <Button
                text="Forward To Admin"
                type="submit"
                disabled={!watch("snoremarks")}
                onClick={() => {
                  submitMutate({
                    projectId,
                    status: "VERIFICATION_PENDING",
                    remarks: watch("snoremarks"),
                  });
                }}
                customStyles={{
                  root: {
                    fontSize: "16px",
                    textWrap: "noWrap",
                  },
                }}
              />
            </Box>
          </>
        )}
    </>
  );
};

export default LandLeaseDetails;
