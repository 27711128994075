import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Input } from "src/components/common/FormComponents";
import Password from "src/components/common/FormComponents/Password";
import { SuccessModal } from "src/components/common/modal";
import Snackbar from "src/components/common/Snackbar";
import { RoleIds } from "src/constants/common";
import errorMessage from "src/constants/errorMessage";
import { path } from "src/constants/path";
import { login } from "src/redux/slices/userDetails";
import { getStyles } from "src/styles/theme";
import { Login as LoginType } from "src/types/api";
import { SnackbarType } from "src/types/common";
import { signIn } from "src/utils/api/signUp";
import defaultStyles, { StylesClasses } from "./styles";

export type LoginForm = {
  userName: string;
  password: string;
  userType: string;
};

const Login = () => {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const [showSnackbar, setShowSnackbar] = useState<SnackbarType>({
    severity: "error",
    message: "",
  });

  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "",
    subheading: "",
    description: "",
    btnText: "",
    type: "",
  });

  const [loading, setIsLoading] = useState<boolean>(false);

  const { control, formState, getValues, handleSubmit } = useForm<LoginForm>({
    mode: "onTouched",
    defaultValues: {
      userName: location?.state?.userId || "",
      password: "",
      userType: "OTHER",
    },
  });

  const loginUser = useMutation("signIn", (data: LoginType) => signIn(data), {
    onSuccess: (res: AxiosResponse) => {
      if (!res.headers["x-access-token"] || !res?.data?.userId) {
        setOpenModal({
          open: true,
          heading: "Login Unsuccessful",
          subheading: "",
          description: "Something went wrong",
          btnText: "",
          type: "unSuccess",
        });
        setIsLoading(false);
        return;
      }

      dispatch(login(res.data));
      setIsLoading(false);
      setShowSnackbar({
        severity: "error",
        message: "Successful",
      });
    },
    onError: (err: AxiosError) => {
      setIsLoading(false);
      /*    setShowSnackbar({
        severity: "error",
        message: err?.response?.data?.message || "Something went wrong",
      }); */
      setOpenModal({
        open: true,
        heading: "Login Unsuccessful",
        subheading: "",
        description: err.response.data.message || "Something went wrong",
        btnText: "",
        type: "unSuccess",
      });
    },
    retry: 1,
  });

  const { errors, isValid } = formState;

  const onboarding = () => {
    navigate(path.onboarding);
  };

  const handleFormSubmit = (data: LoginForm) => {
    setIsLoading(true);
    loginUser.mutate({
      ...data,
      roleId: RoleIds["Green Credit Project Developer"],
    });
  };

  const marqueeData = [
    {
      text: "Forest dept has started land registration. Registration of entities will start on 6th March 2024..   ",
    },
  ];

  return (
    <>
      <Box {...styles("container")}>
        <Box {...styles("logInWrapper")}>
          {/* <MarqueHeader data={marqueeData} /> */}
          <Box {...styles("logo")}>
            <Box
              component={"img"}
              src="/assets/images/coloredLeafIcon.png"
            ></Box>
            <Box {...styles("title")}>GREEN CREDIT</Box>
          </Box>
          <Box {...styles("welcomeBox")}>
            <Box {...styles("welcome")}>
              Welcome to&nbsp;
              <Box component={"span"} {...styles("welcomeSpan")}>
                Green Credit Programme
              </Box>
            </Box>
          </Box>

          <Box {...styles("heading")}>Login</Box>
          <Box
            {...styles("loginFields")}
            component="form"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Box {...styles("input")}>
              <Input
                name="userName"
                label="User ID"
                placeholder="Enter your User ID/ Email/ Phone Number"
                control={control}
                height="52px"
                rules={{ required: errorMessage.required }}
              />
            </Box>
            <Box {...styles("password")}>
              <Password
                name="password"
                label="Password"
                placeholder="Enter your password"
                control={control}
                getValues={getValues}
                height="52px"
                errors={errors}
                rules={{
                  required: errorMessage.required,
                }}
                customStyles={{
                  formHelperText: {
                    bottom: "-13px",
                  },
                }}
              />
              <Box
                {...styles("forgetPassword")}
                onClick={() => navigate(path.forgetPassword)}
              >
                Forgot Password
              </Box>
            </Box>
            <Box {...styles("logInBtn")}>
              <Button
                text="Login"
                type="submit"
                disabled={!isValid}
                loading={loading}
                customStyles={{
                  root: defaultStyles.loginBtn,
                }}
              />
            </Box>

            <Box {...styles("noAccount")}>
              No Account?{" "}
              <Box
                {...styles("signUp")}
                component="span"
                onClick={() => onboarding()}
              >
                Sign up
              </Box>
            </Box>
          </Box>

          <Snackbar
            open={!!showSnackbar.message}
            message={showSnackbar.message}
            severity={showSnackbar.severity}
            resetSnackbar={() =>
              setShowSnackbar({ ...showSnackbar, message: "" })
            }
          />
        </Box>
        <SuccessModal
          open={openModal.open}
          heading={openModal.heading}
          subheading={openModal.subheading}
          closeOnBackDrop
          description={openModal.description}
          btnText={openModal.btnText}
          type={openModal.type}
          // onClick={() => handleResponseModal()}
          handleModalClose={() => setOpenModal({ ...openModal, open: false })}
        />
      </Box>
    </>
  );
};

export default Login;
